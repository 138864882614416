

.twins.modals {
  position: relative;
  height: 100%;

  .item-wrapper {
    max-width: 1500px;
    height: auto;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
    .item {
      position: relative;
      display: inline-block;
      margin-top: 10vh;
      height: 70vh;
      width: 47.5%;
      @media (max-width: 768px) {
        height: 50vh;
        margin-top: 0;
      }
      &:first-child {
        margin-right: 5%;
      }
      &:hover .item-image {
          transition: .5s ease-in;
          filter: brightness(50%);
          // filter: blur(5px) brightness(50%);
      }
      .item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .5s ease-out;
      }
      .button {
        position: absolute;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px white solid;
        width: 50%;
        height: auto;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0;
        transition: .5s ease-out;
        p {
          @include font-size(1.5rem);
          font-family: 'book';
          @media (max-width: 768px){
            font-size: 24px;
          }
        }
      }
    }
    .item1:hover .button {
      top: 50%;
      opacity: 1;
      transition: .5s ease-in;
    }
    .item2:hover .button {
      top: 50%;
      opacity: 1;
      transition: .5s ease-in;
    }
    .h2 {
      margin-top: 5%;
      font-family: "light";
      @include font-size(2.8rem);
    }
  }
}

