.header {
    transition: .2s;
    color: #FFFFFF;
    position: fixed;
    width: 100vw;
    top: -10vh;
    left: 0;
    z-index: 999;
    height: 10vh;
    margin-bottom: -10vh;
    ul {
      display: none;
    }
    @media (min-width: 768px){
      
      .open-and-close {
        display: none;
      }

      .mobile {
        display: none;
      }

      ul.not-mobile {
        display: block;
        text-transform: uppercase;
        color: white;
        font-family: "book";
        margin: 0 auto;
        padding: 40px 0 0 0;
        width:90%;
        z-index: 1;
        text-align: right;
        img {
          float: left;
        }
        .language-button {
          display: inline-block;
          &:first-of-type {
            margin-right: 1vw;
            &:before {
              content: "|";
              margin-right: 2vw;
            }
          }
          button{
            opacity: .5;
          }
        }
        a {
          margin-right: 4vw;
          &:last-child {
            margin-right: 0;
          }
        }
        .last {
          margin-right: 2vw;
        }
        li {  
          transition: .2s;
          @include font-size(1rem);      
          list-style-type: none;
          display: inline-block;
        }
      }
    }
    @media (max-width: 767px){
  
    .open-and-close {
      border: none;
      background-color: transparent;
      position: absolute;
      top: 20px;
      right: 20px;
      color: white;
    }

    ul.mobile {
      background-color: black;
      display: block;
      text-transform: uppercase;
      color: white;
      font-family: "book";
      margin: 0 auto;
      padding: 40px 0 0 0;
      width: 90%;
      height: 100vh;
      z-index: 1;
      text-align: left;
      padding: 0 5%;
      padding-top: 15vh;
      &.closed {
        display: none;
      }
      img {
        float: left;
      }

      a {
        color: white;
        text-decoration: none;
        .imprint {
          margin-top: 5vh;
          font-size: 20px;
        }
      }

      .language-button {
        display: inline-block;
        margin-right: 5vw;
        margin-bottom: 5vh;
        font-size: 20px;
        width: auto;
        button {
          opacity: .5;
        }
      }

      li {  
        width: 100%;
        transition: .2s;
        font-size: 42px;     
        list-style-type: none;
        display: inline-block;
        .boewer-logo {
          background-color: green;
        }
      }
    }
  }
}

.language-button {
  &.active {
    cursor: default;
    button {
      cursor: pointer;
      border-bottom: 1px solid #FFF;
      opacity: 1 !important;
    } 
  }
}


//mobile
.mobile li.boewer-logo {
  margin: 3vh 0 0 3vh;
  list-style-type: none;
  display: inline-block;
  transition: 2s;
}

.mobile li.boewer-logo.hidden {
  display: none;
}

.icon {
  .burgerIcon {
    width: 30px;
    display: block;
    &.hidden{ display: none; }

  }
  .exit {
    width: 30px;
    &.hidden{ display: none; }
  }
}

#force-white {
  color: white !important;
}

