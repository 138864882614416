
.image-then-text {
  .image-overlay {
    opacity: .8;
    width: 100vw;
    height: 200vh;
    position: absolute;
    top: -100vh;
    color: white;
  }
  .fullscreen-text {
    top: 120vh;
    @media (min-width: 1200px){
      font-size: 3rem;
      margin-top: 0;
    }
    @media (max-width: 768px){
      top: 50%;
      margin-top: 10vh;
      transform: translate(-50%, 0);
    }
  }
}