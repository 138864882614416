

#intro {
  position: fixed;
  background-color: black;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  display: block;
  #introLogo {
    opacity: 0;
    object-fit: contain;
    position: relative;
    height: 100vh;
    width: 100vw;
  }
}

@keyframes logoAnimation {
    0%   {opacity: 0;}
    25%  {opacity: 1;}
    75%  {opacity: 1; width: 100vw;}
    100% {opacity: 0; width: 60vw;}
}

@keyframes introAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}