
@font-face {
  font-family: "light";
  src: url("./fonts/GT-Sectra-Display-Light.eot") format("eot"),
     url("./fonts/GT-Sectra-Display-Light.woff2") format("woff2"),
     url("./fonts/GT-Sectra-Display-Light.woff") format("woff"),
     url("./fonts/GT-Sectra-Display-Light.ttf") format("truetype")
}

@font-face {
  font-family: "book";
  font-weight: 200;
  src: url("./fonts/GT-Sectra-Fine-Book.eot?") format("eot"),
     url("./fonts/GT-Sectra-Fine-Book.woff2") format("woff2"),
     url("./fonts/GT-Sectra-Fine-Book.woff") format("woff"),
     url("./fonts/GT-Sectra-Fine-Book.ttf") format("truetype")
}