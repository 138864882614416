@import "../node_modules/rfs/scss";
@import "sass/variables.scss";
@import "sass/header.scss";
@import "sass/intro.scss";
@import "sass/landing.scss";
@import "sass/centeredText.scss";
@import "sass/centeredImage.scss";
@import "sass/twins.scss";
@import "sass/fullImage.scss";
@import "sass/fullText.scss";
@import "sass/imagethentext.scss";
@import "sass/textAndImageAndOverlay.scss";
@import "sass/footer.scss";


html {
  background-color: black;
}

#root {
  background-color: #FFFFFF;
  text-align: center;
  font-family: times;
}

.App {
  overflow-x: hidden;
  margin: 0 auto;
  background-color: black;
}

.fullscreen {
  margin: 0;
  height: 100vh;
  width: 100%;
  position: relative;
}

.text-align-left {
  text-align: left;
}

.boewer-logo {
  width: auto;
  height: 22px;
  float: left; 
}

.link {
  color: white;
  text-decoration: none;
  outline: none;
  &:visited {
    outline: none;
  }
  &:hover {
    outline: none; 
  }
  &:focus {
    outline: none;
  }
}

li {
  cursor: pointer;
}


  #trigger1,
  #trigger3 {
    background-color: white;
  }

  .header.notrigger-active {
    li {
      color: white !important;
      transition: .2s;
      .logo-color {
        transition: .2s;
        fill: #FFF !important;
      }
    }
    .mobile .icon .logo-color {
      transition: .2s;
      stroke: #FFF !important;
    }
  }

  .header.trigger1-active {
    li {
      color: black !important;
      transition: .2s;
      .logo-color {
        transition: .2s;
        fill: #000 !important;
      } 
    }
    .mobile .icon .logo-color {
      transition: .2s;
      stroke: #000 !important;
    }  
  }

  .header.trigger2-active {
    li {
      color: white !important;
      transition: .2s;
      .logo-color {
        transition: .2s;
        fill: #FFF !important;
      }
    }
    .mobile .icon .logo-color {
      transition: .2s;
      stroke: #FFF !important;
    }
  }

  .header.trigger3-active {
    li {
      color: black !important;
      transition: .2s;
      .logo-color {
        transition: .2s;
        fill: #000 !important;
      }
    }
    .mobile .icon .logo-color {
      transition: .2s;
      stroke: #000 !important;
    }
  }

  .header.trigger4-active {
    li {
      color: white !important;
      transition: .2s;
      .logo-color {
        transition: .2s;
        fill: #FFF !important;
      }
    }
    .mobile .icon .logo-color {
        transition: .2s;
        stroke: #FFF !important;
    }
  }

  .header.trigger5-active {
    li {
      color: white !important;
      transition: .2s;
      .logo-color {
        transition: .2s;
        fill: #FFF !important;
      }
    }
    .mobile .icon .logo-color {
        transition: .2s;
        stroke: #FFF !important;
    }
  }

  .header.trigger6-active {
    li {
      color: white !important;
      transition: .2s;
      .logo-color {
        transition: .2s;
        fill: #FFF !important;
      }
    }      
    .mobile .icon .logo-color {
        transition: .2s;
        stroke: #FFF !important;
    }
  }

  .relative {
    position: relative;
  }

.styles_overlay__CLSq- {
  transition: .2s !important;
}

.styles_modalCenter__L9F2w {
  @include font-size(2.5rem);
  font-family: "light";
  height: 80vh;
  @media (max-width: 768px) {
    height: auto;
  }
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 5vh;
  .h1 {
    @include font-size(3rem);
  }
}

svg {
  width: 100px;
}

.modal {
  .link {
    color: black;
    border-bottom: 1px solid black;
  }
}

.styles_closeButton__20ID4:active,
.styles_closeButton__20ID4:visited,
.styles_closeButton__20ID4:focus,
.styles_closeButton__20ID4.active,
.styles_closeButton__20ID4:focus:active {
  box-shadow: none;
  outline: none;
  -webkit-box-shadow: none;
}

.styles_overlay__CLSq-.overlay-modal.overlay-modal2.undefined {
  background-color: #F4E7DF;
}

.styles_overlay__CLSq-.overlay-modal.overlay-modal1.undefined {
  background-color: #D8DCE8;
}

@keyframes headerAnimation {
  from {
    top: -10vh;
  }
  to {
    top: 0;
  }
}


//button normalize

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  padding: 0;
}
