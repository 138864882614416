
.fullscreen.footer {

  .header.white {
    margin-bottom: 0;
  }
  .overlay {
    position: absolute;
    top: -100vh;
    height: 200vh;
    width: 100vw;
  }
  .twins {
    width: 90vw;
    position: absolute;
    overflow: hidden;
    margin: 0 auto;
    background-color: #000;
    top: 100vh;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    width: 100%;
    color: #FFF;
    text-align: left;
    font-family: "light";
    @include font-size(2rem);
    .item-wrapper {
      background-color: black;
      max-width: none;
      width: 90%;
      position: absolute;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .first {
      margin-top: 0;
      @media (max-with: 1440px){
        font-size: 44px;
      }
    }
    .item {
      width: 50%;
      height: 100%;
      margin-top: 0;
      float: right;
      &:hover .item-image{
        filter: none;
      }
      @media (max-width: 768px){
        display: block;
        width: 100%;
        height: 40vh;
        &.item1 {
          margin-top: 10%;
        }
      }
      .wrapper {
        margin-top: 50%;
        transform: translateY(-50%);
      } 
      &:first-child {
        margin-right: 0;
      }
      .item-image {
        margin-top:50%;
        transform: translateY(-50%);
        height: 40vh;
        width: 100%;
      }
    }
  }
}

.imprint-wrapper {
  font-family: "book";
  background-color: black;
  color: white;
  @include font-size(2rem);
  text-align: left;
  margin-top: 10vh 0 !important;
  @media (max-width: 768px){
    font-size: 16px;
  }
  .imprint {
    height: 100%;
    width: 90vw;
    margin: 10vh auto 5vh;
  }
}