 
 .text-and-image-and-overlay {

  .footerTrigger {
    width: 100%;
    height: 10vh;
  }

  .h2 {
    @media (min-width: 1440px){
      font-size: 44px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      text-align: left;
    }
  }

  .centered-image {
    width: 50vw;
    max-width: 900px;
    @media (max-width: 768px) {
      width: 70vw;
    }
  }
  .fullscreen-text-overlay {
    background-color: #000;
    position: absolute;
    top: -100vh;
    height: 200vh;
    width: 100vw;
    .fullscreen-text {
      @media (min-width: 1500px){
        font-size: 3rem;
      }
      max-height: 78vh;
      margin-bottom: 20vh;
      top: 120vh;
      overflow-y: scroll;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      &:-webkit-scrollbar { width: 0 !important }
    }
  }
 }