.landing {
  position: relative;
  z-index: 4;

  .landing-img {
    object-fit: cover;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left:0;
    top:0;
    z-index: 0;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 100% !important;
      height: 100% !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important; 
    }
  }

  .h1 {
    font-family: "light";
    margin: 0;
    opacity: 0;
    margin-top: 40vh;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include font-size(5rem);
    color: #FEFEFE;
  }
}

.full-logo-animate {
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-name: logoFadeOut;
  animation-fill-mode: forwards;

  background: fixed;
  width: 100vw;
  position: absolute;
  height: auto;
  top: 0;
  z-index: 9999;
  opacity: 1;
  background-color: black;
  .fullscreen {
    opacity: 0;
    object-fit: contain;
  }
}

@keyframes h1Animation {
  from { 
    margin-top: 40vh; 
    opacity: 0;
  }
  to { 
    margin-top: 0vh; 
    opacity: 1;
  }
}


