
.centered-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 1200px;
  p {
    font-family: "light";
    @include font-size(3rem);
  } 
  .h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 32px;
    font-family: "light";
  }
  .text-overflow p {
    overflow-y: scroll;
  }
}