
.fullscreen-text {
  width: 90%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0%);
  font-family: "light";
  @include font-size(2rem);
  .smaller {
    @include font-size(1.8rem);
  }
}