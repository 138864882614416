
.full-image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.centered-text-background1 {
  opacity: .9;
  background-color: #1E4A40;
  color: #FFFFFF;
}

.centered-text-background2 {
  opacity: .9;
  background-color: #201D40;
  color: #FFFFFF;
}

.centered-text-background3 {
  opacity: .9;
  background-color: #022A43;
  color: #FFFFFF;
}

.centered-text-background4 {
  z-index: 3;
  color: #FFFFFF;
  .fullscreen-text {
    position: relative;
  }
}

